import {Button, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {useRouter} from 'next/router';
import {FC} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

const PREFIX = 'ErrorPage';

const classes = {
    contentArea: `${PREFIX}-contentArea`,
    supportLink: `${PREFIX}-supportLink`,
    wrapper: `${PREFIX}-wrapper`,
};

const Root = styled('div')(({theme}) => ({
    [`& .${classes.contentArea}`]: {
        padding: 20,
    },

    [`& .${classes.supportLink}`]: {
        color: theme.palette.primary.main,
    },

    [`&.${classes.wrapper}`]: {
        textAlign: 'center',
    },
}));

type Props = {
    readonly statusCode: number;
};

/**
 * Komponenta zobrazí obsah s popisem chyby podle HTTP kódu, který obdrží.
 * Pro případ, kdy by uživatel přešel přímo na routu /_error, je to bráno jako chyba 404 (not found).
 * Routa /_error je dostupná přímo i v dev režimu.
 */
export const ErrorPage: FC<Props> = ({statusCode}) => {
    const {push} = useRouter();
    const {formatMessage} = useIntl();
    /**
     * Přesměruje uživatele na hlavní stránku (root)
     */
    const handleRootRedirect = () => push('/');

    /**
     * Vrátí nadpis chyby podle http status kódu.
     *
     * @param {number} code - http status code
     */
    const getTitleByStatusCode = (code: number) => {
        switch (code) {
            case 200:
            case 404:
                return <FormattedMessage id="error.http.notFoundTitle" />;
            case 500:
                return <FormattedMessage id="error.http.internalServerErrorTitle" />;
            default:
                return <FormattedMessage id="error.http.unexpectedTitle" />;
        }
    };

    /**
     * Vrátí informační obsah chyby pro uživatele podle http status kódu.
     *
     * @param {number} code
     */
    const getContentByStatusCode = (code: number) => {
        switch (code) {
            // handling pro případ, kdy by uživatel přešel přímo na routu /_error, tak se mu vrátí 200
            case 200:
            // not found
            case 404:
                return (
                    <Typography>
                        <FormattedMessage id="error.http.hint" />
                    </Typography>
                );
            // internal server error
            case 500:
                return (
                    <>
                        <Typography>
                            <FormattedMessage id="error.http.internalServerErrorContent1" />
                        </Typography>
                        <Typography>
                            <FormattedMessage id="error.http.hint" />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="error.http.internalServerErrorContent2"
                                values={{
                                    kontakt: (
                                        <a className={classes.supportLink} href={`mailto:${formatMessage({id: 'support.email'})}`}>
                                            {formatMessage({id: 'support.email'})}
                                        </a>
                                    ),
                                }}
                            />
                        </Typography>
                    </>
                );
            // vše ostatní
            default:
                return (
                    <>
                        <Typography>
                            <FormattedMessage id="error.http.unexpectedContent1" />
                        </Typography>
                        <Typography>
                            <FormattedMessage id="error.http.unexpectedContent2" />
                        </Typography>
                        <Typography>
                            <FormattedMessage id="error.http.hint" />
                        </Typography>
                    </>
                );
        }
    };

    return (
        <Root className={classes.wrapper}>
            <Typography variant="h2">{getTitleByStatusCode(statusCode)}</Typography>
            <div className={classes.contentArea}>{getContentByStatusCode(statusCode)}</div>
            <Button color="primary" variant="contained" disableRipple onClick={handleRootRedirect}>
                <FormattedMessage id="button.root" />
            </Button>
        </Root>
    );
};
